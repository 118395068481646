import React from 'react'
import { Link } from '@deque/cauldron-react'
import { freeTrialClick } from '../../../lib/analytics'
import { useGatsbyPageContext } from '../../../context/GatsbyPageContext'

interface FreeTrialLinkProps {
  className?: string
  thin?: boolean
  url: string
  variant?: 'button' | 'button-secondary'
}

export default function FreeTrialLink(props: FreeTrialLinkProps) {
  const { className, thin, url } = props
  const variant = props.variant || 'button'
  const { productId, version, locale, pageId } = useGatsbyPageContext()

  return (
    <Link
      className={className}
      href={url}
      target="_blank"
      variant={variant}
      thin={thin}
      onClick={() =>
        freeTrialClick({
          productId,
          version,
          locale,
          pageId,
        })
      }
    >
      <span>Free Trial</span>
    </Link>
  )
}
