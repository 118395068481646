import React from 'react'
import { useAuth } from '../context/Auth'
import './AccessRestricted.scss'

declare const RESTRICTED_ACCESS_HELP_EMAIL: string

export default function AccessRestricted() {
  const authState = useAuth().state
  const { keycloak, profile } = authState
  const { firstName, username } = profile

  return (
    <div className="AccessRestricted">
      <h1>Access Restricted</h1>
      <p>
        Sorry
        {profile ? `, ${firstName || username}` : ``}. You have a valid account
        on the {(keycloak?.realm || '').toUpperCase()} realm, but unfortunately
        you don't have access to this resource.
      </p>
      <p>
        Please contact{' '}
        <a href={`mailto:${RESTRICTED_ACCESS_HELP_EMAIL}`}>
          {RESTRICTED_ACCESS_HELP_EMAIL}
        </a>{' '}
        for more information.
      </p>
      <p>
        You can also{' '}
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault()
            keycloak?.logout()
          }}
        >
          sign out
        </a>{' '}
        and try again using a different user account.
      </p>
      <p>Our apologies for the inconvenience.</p>
    </div>
  )
}
