import React from 'react'
import { TopBar } from '@deque/cauldron-react'
import classNames from 'classnames'
import './index.scss'

interface Props {
  children?: React.ReactNode
  centerAlign?: boolean
}

export default function Header(props: Props) {
  const { children, centerAlign } = props

  return (
    <div
      className={classNames({ Header: true, 'center-align': centerAlign })}
      role="banner"
    >
      <TopBar>{children}</TopBar>
    </div>
  )
}
