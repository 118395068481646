import React, { useEffect } from 'react'
import { authenticate, useAuth } from '../context/Auth'
import isBrowser from '../lib/is-browser'
import LoaderPage from './LoaderPage'

export default function Login() {
  // Keycloak instance is not available in non-browser runtimes
  if (!isBrowser()) {
    return null
  }
  const { state: authState, dispatch: authDispatch } = useAuth()
  const { keycloak } = authState

  useEffect(() => {
    authenticate(keycloak, authDispatch)
  })

  return <LoaderPage />
}
