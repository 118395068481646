import React, { useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import './index.scss'
import { Icon, Offscreen, Toast, Tooltip } from '@deque/cauldron-react'
import isBrowser from '../../../lib/is-browser'
import { useGatsbyPageContext } from '../../../context/GatsbyPageContext'
import FreeTrialLink from '../FreeTrialLink'

interface HeaderProps {
  title: string
  productVersion: string
  mainRef: React.RefObject<HTMLDivElement>
  showVersionModal: () => void
  subtitle?: string
}

export default function ArticleHeader(props: HeaderProps) {
  const { title, productVersion, mainRef, showVersionModal, subtitle } = props
  const { freeTrialUrl } = useGatsbyPageContext()

  const [showCopyToast, setShowCopyToast] = useState(false)
  const copyButtonRef: React.RefObject<HTMLButtonElement> = useRef(null)
  const versionButtonRef: React.RefObject<HTMLButtonElement> = useRef(null)
  const clipboardUrl = isBrowser() ? location.origin + location.pathname : ''

  const COPY_TO_CLIPBOARD = 'Copy page location to clipboard'
  const CHANGE_PRODUCT_VERSION = 'Change product version'

  return (
    <div className="Article__Header">
      <div className="Title">
        <div className="Title__Heading">
          <h1>{title}</h1>
        </div>
        <Toast
          type="confirmation"
          show={showCopyToast}
          onDismiss={() => {
            setShowCopyToast(false)
            mainRef.current?.focus()
          }}
        >
          <span>
            Link to <strong>{title}</strong> copied to clipboard
          </span>
        </Toast>
        <CopyToClipboard
          text={clipboardUrl}
          onCopy={() => setShowCopyToast(true)}
        >
          <button className="button" ref={copyButtonRef}>
            <img src="/images/light/icons/copy.svg" alt={COPY_TO_CLIPBOARD} />
          </button>
        </CopyToClipboard>
        <Tooltip target={copyButtonRef} placement="top">
          <span>{COPY_TO_CLIPBOARD}</span>
        </Tooltip>
      </div>
      {subtitle && (
        <div className="Subtitle">
          <p>{subtitle}</p>
        </div>
      )}
      {freeTrialUrl && (
        <FreeTrialLink
          className="FreeTrial"
          url={freeTrialUrl}
          variant="button-secondary"
        />
      )}
      <div className="VersionSelect">
        <button
          ref={versionButtonRef}
          onClick={(e) => {
            e.preventDefault()
            showVersionModal()
          }}
        >
          <Offscreen>{CHANGE_PRODUCT_VERSION}</Offscreen>
          <span>v</span>
          <span>{productVersion}</span>
          <Icon type="triangle-down" />
        </button>
        <Tooltip target={versionButtonRef} placement="right">
          <span>{CHANGE_PRODUCT_VERSION}</span>
        </Tooltip>
      </div>
    </div>
  )
}
