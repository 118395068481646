/**
 * Metadata corresponding to each product-specific content package.
 */
let contentPackages: ContentPackage[] = [
  {
    id: 'devtools-for-web',
    name: 'axe DevTools® for Web',
    description:
      'The most powerful and accurate accessibility toolkit on the market can get you to 80% issue coverage—or more—during development.',
    public: true,
    sourcePages: true,
    includeInSiteSearch: true,
    includeInMainNav: true,
    internalPackage: true,
  },
  {
    id: 'devtools-mobile',
    name: 'axe DevTools® for Mobile',
    description:
      'The most comprehensive and accurate accessibility toolkit for testing iOS and Android apps built with any language - native or not.',
    public: true,
    sourcePages: true,
    includeInSiteSearch: true,
    includeInMainNav: true,
    internalPackage: true,
    freeTrialUrl:
      'https://axe.deque.com/signup?product=axe-devtools-mobile&redirect_uri=https%3A%2F%2Faxe.deque.com%2Faxe-devtools-mobile%2Fget-started?utm_source=docs_site&utm_medium=referral&utm_campaign=free_trial_mobile',
  },
  {
    id: 'linter',
    name: 'axe DevTools® Linter',
    description:
      'Accessibility linting for HTML, Angular, React, Markdown, and Vue.',
    public: true,
    sourcePages: true,
    includeInSiteSearch: true,
    includeInMainNav: true,
    internalPackage: true,
    freeTrialUrl:
      'https://axe.deque.com/axe-devtools-linter/trial?utm_source=docs_site&utm_medium=referral',
  },
  {
    id: 'reports',
    name: 'axe® Reports',
    description:
      'Enterprise reporting interface designed to help you understand your accessibility issues and trends.',
    public: true,
    sourcePages: true,
    includeInSiteSearch: true,
    includeInMainNav: true,
    internalPackage: true,
  },
  {
    id: 'auditor',
    name: 'axe Auditor®',
    description:
      'Manual accessibility testing solution for Accessibility Testing Specialists that allows you to optimize your accessibility testing, tracking, and reporting with step-by-step guidance and report building.',
    public: true,
    sourcePages: true,
    includeInSiteSearch: true,
    includeInMainNav: true,
    internalPackage: true,
  },
  {
    id: 'devtools-server',
    name: 'axe® Account Portal',
    description: 'Manage axe users and subscriptions.',
    public: true,
    sourcePages: true,
    includeInSiteSearch: true,
    includeInMainNav: true,
    internalPackage: true,
  },
  {
    id: 'monitor',
    name: 'axe Monitor®',
    description:
      'Dynamically scan, monitor and report on the accessibility status of your entire site. Pinpoint improvements or what’s declined over time and drill-down to perform root cause analysis.',
    public: true,
    sourcePages: true,
    includeInSiteSearch: true,
    includeInMainNav: true,
    internalPackage: true,
  },
  {
    id: 'developer-hub',
    name: 'axe® Developer Hub',
    description:
      'An integrated accessibility testing solution that makes it easy to incorporate accessibility testing into your current test codebase, track your accessibility results over time, and share them with teammates.',
    public: true,
    sourcePages: true,
    includeInSiteSearch: true,
    includeInMainNav: true,
    internalPackage: true,
    freeTrialUrl:
      'https://axe.deque.com/signup?product=axe-devtools-watcher&redirect_uri=https%3A%2F%2Faxe.deque.com%2Faxe-watcher%2Fstartup',
  },
  {
    id: 'issue-help',
    name: 'axe® Issue Help',
    description: '',
    public: true,
    sourcePages: true,
    includeInSiteSearch: false,
    includeInMainNav: false,
    internalPackage: true,
  },
  {
    id: 'axe-release-impact',
    name: 'axe-core® Release Impact',
    description:
      'Release notes for axe-core releases and additional information on how each axe-core release impacts other axe Tools.',
    public: true,
    sourcePages: true,
    includeInSiteSearch: false,
    includeInMainNav: true,
    internalPackage: true,
  },
  {
    id: 'deque-university',
    name: 'Deque University',
    description:
      ' Comprehensive accessibility training content for web, documents, native mobile apps, IAAP certification exam prep, customer service, and more, with role-based learning path options.',
    public: true,
    sourcePages: true,
    includeInSiteSearch: true,
    includeInMainNav: true,
    internalPackage: true,
  },
  {
    id: 'for-designers',
    name: 'axe® for Designers',
    description:
      'Figma plugin that allows designers to test their designs for accessibility issues.',
    public: true,
    sourcePages: false,
    includeInSiteSearch: false,
    includeInMainNav: false,
    internalPackage: true,
  },
]

if (process.env.PREVIEW === 'true') {
  contentPackages = contentPackages.map((contentPackage) => {
    return {
      ...contentPackage,
      // Always source pages in preview environments
      sourcePages: true,
      // Never link to external sites in preview environments
      url: undefined,
    }
  })
}

// Filter packages using optional `PRODUCTS` env variable.
contentPackages = contentPackages.filter(({ id }) => {
  if (typeof process.env.PRODUCTS !== `string`) {
    return true
  }
  return process.env.PRODUCTS.split(`,`)
    .map((id) => id.trim())
    .includes(id)
})

export default contentPackages
