/**
 * Create a formatted document title suitable as a value for the `<title>` tag of the document `<head>`.
 *
 * If a page title is not provided, will simply use the site title alone.
 */

export default function createDocumentTitle(
  siteTitle: string,
  pageTitle?: string
): string {
  if (!siteTitle) {
    throw new TypeError('The `siteTitle` argument must be a string.')
  }
  if (!pageTitle) {
    return siteTitle
  }
  // Use vertical bar separator to align with deque.com's approach
  return `${pageTitle} | ${siteTitle}`
}
