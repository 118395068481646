import { Icon } from '@deque/cauldron-react'
import classNames from 'classnames'
import { Link } from 'gatsby'
import React from 'react'
import { useGatsbyPageContext } from '../../../context/GatsbyPageContext'
import './index.scss'
import { validate as isUuid } from 'uuid'
import FreeTrialLink from '../FreeTrialLink'

interface BreadcrumbProps {
  collapsed: boolean
  toggleNav: () => void
  showNavButtonRef: React.RefObject<HTMLButtonElement>
}

export default function Breadcrumb(props: BreadcrumbProps) {
  const { collapsed, toggleNav, showNavButtonRef } = props
  const { productId, version, locale, breadcrumbs, freeTrialUrl } =
    useGatsbyPageContext()

  return (
    <div
      className={classNames('Breadcrumb__Dock', {
        'Breadcrumb__Dock--collapsed': collapsed,
      })}
    >
      <div className="MenuTrigger">
        <button
          ref={showNavButtonRef}
          aria-haspopup="true"
          onClick={(e) => {
            e.preventDefault()
            toggleNav()
          }}
        >
          <Icon type="hamburger-menu" label="Show navigation" />
        </button>
      </div>
      <div
        className="Breadcrumb__Nav"
        role="navigation"
        aria-label="Breadcrumb"
      >
        <ul>
          {breadcrumbs.map(({ id, title }, i) => {
            return (
              <li key={id}>
                {i < breadcrumbs.length - 1 && !isUuid(id) ? (
                  <Link to={`/${productId}/${version}/${locale}/${id}`}>
                    <span>{title}</span>
                  </Link>
                ) : (
                  <span>{title}</span>
                )}
              </li>
            )
          })}
        </ul>
      </div>
      {freeTrialUrl && (
        <FreeTrialLink
          className="Breadcrumb__FreeTrial"
          url={freeTrialUrl}
          variant="button-secondary"
          thin
        />
      )}
    </div>
  )
}
