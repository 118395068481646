import { navigate } from '@reach/router'
import React from 'react'
import { closeSearchModal, useSearch } from '../../../../../context/Search'
import Icon from './Icon'
import './index.scss'

interface HitProps {
  item: ResultItem
  groupRef: React.RefObject<HTMLDivElement>
}

export default function Item({ item, groupRef }: HitProps) {
  const { state, dispatch } = useSearch()
  const { selectedItem } = state
  const anchorRef = React.useRef<HTMLAnchorElement>(null)
  const type = itemType(item)
  const text = itemText(type, item)
  const isSelected = selectedItem && selectedItem.itemId === item.itemId

  // Scroll selected item into view
  React.useEffect(() => {
    if (!isSelected || !groupRef.current || !anchorRef.current) {
      return
    }
    // Scroll group into view
    groupRef.current.scrollIntoView({ block: 'nearest' })
    // Scroll anchor into view in case group height exceeds container height
    anchorRef.current.scrollIntoView({ block: 'nearest' })
  })

  const handleClick = (e: React.MouseEvent): void => {
    e.preventDefault()
    closeSearchModal(dispatch)
    navigate(item.url)
  }

  return (
    // Using anchor tag for destination URL tooltip
    <a
      className="Item"
      id={item.itemId}
      href={item.url}
      ref={anchorRef}
      tabIndex={-1}
      role="option"
      aria-selected={isSelected}
      onClick={handleClick}
    >
      <div className="Icon">
        <Icon type={type} />
      </div>
      <div className="Text">
        <div
          className="Title"
          dangerouslySetInnerHTML={{ __html: text.title }}
        ></div>
        {text.subtitle && (
          <div className="Subtitle">
            <span className="Offscreen">Result context:</span>
            <span dangerouslySetInnerHTML={{ __html: text.subtitle }}></span>
          </div>
        )}
      </div>
    </a>
  )
}

function itemType(item: ResultItem): HitType {
  const hierarchy = item._highlightResult.hierarchy

  if (item._snippetResult) {
    return 'content'
  }

  let res: HitType = 'lvl0'
  for (const attr in hierarchy) {
    if (!hierarchy[attr as keyof HitHighlightResultHierarchy]) {
      continue
    }
    res = attr as HitType
  }

  return res
}

function itemText(type: HitType, item: ResultItem): ResultItemText {
  const { _highlightResult, _snippetResult } = item
  const hierarchy = _highlightResult.hierarchy

  switch (type) {
    case 'lvl0':
      return {
        // Only display the level-1 heading
        title: hierarchy.lvl0.value,
        subtitle: undefined,
      }
    case 'content':
      return {
        // Display the matching content snippet
        // (The `_snippetResult` property is always/only present on content-type hits)
        title: (_snippetResult as SnippetResult).content.value,
        // Include the nearest heading as a subtitle for context
        subtitle: getMostSpecificAttrValue(item),
      }
    default:
      const mostSpecificAttrValue = getMostSpecificAttrValue(item)
      const lastMatchingHierarchyValue = getMostSpecificMatchingAttrValue(item)
      return {
        title: mostSpecificAttrValue,
        subtitle:
          // Omit the subtitle if the most specific attribute value is the same as the last matching value
          mostSpecificAttrValue != lastMatchingHierarchyValue
            ? lastMatchingHierarchyValue
            : undefined,
      }
  }
}

/**
 * Get the most specific (highest number) attribute from a hit's highlight result hierarchy, regardless of whether its contents contains a term match.
 */

function getMostSpecificAttrValue(hit: Hit): string {
  const hierarchy = hit._highlightResult.hierarchy
  const lastHierarchyAttr = Object.keys(hierarchy).pop() as string
  return (
    hierarchy[
      lastHierarchyAttr as keyof HitHighlightResultHierarchy
    ] as ResultContent
  ).value
}

/**
 * Get the most specific (highest number) attribute from a hit's highlight result hierarchy that contains a term match.
 */

function getMostSpecificMatchingAttrValue(hit: Hit): string {
  const hierarchy = hit._highlightResult.hierarchy

  let result: string = ''
  for (const attr in hierarchy) {
    const { value, matchLevel } = hierarchy[
      attr as keyof HitHighlightResultHierarchy
    ] as ResultContent
    if (matchLevel === 'partial' || matchLevel === 'full') {
      result = value
    }
  }

  return result
}
