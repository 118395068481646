import React from 'react'
import { useAuth } from '../context/Auth'
import { useGatsbyPageContext } from '../context/GatsbyPageContext'
import hrefHasHelpKey from '../lib/href-has-helpkey'
import AccessRestricted from './AccessRestricted'
import Login from './Login'

type AuthenticatorProps = {
  children: React.ReactElement
  disable: boolean
}

export function Authenticator(props: AuthenticatorProps) {
  const { children, disable } = props

  if (disable) {
    return <>{children}</>
  }

  const { state: authState, dispatch: authDispatch } = useAuth()
  const { keycloak, authenticated } = authState
  const { isPublic, allowedRolesString, helpKeysString } =
    useGatsbyPageContext()
  const allowedRoles: string[] = JSON.parse(allowedRolesString)
  const helpKeys: string[] = JSON.parse(helpKeysString)
  const bypassAuth = isPublic || hrefHasHelpKey(helpKeys)

  if (bypassAuth) {
    return children
  }

  if (authenticated) {
    const userHasAccess =
      allowedRoles.length <= 0 ||
      allowedRoles.some((role) => keycloak.hasResourceRole(role))
    return <>{userHasAccess ? children : <AccessRestricted />}</>
  }

  return <Login />
}
