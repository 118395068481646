export default function flattenItems(
  items: NavTypes.NormalizedNavItem[]
): NavTypes.NormalizedNavItem[] {
  let res: NavTypes.NormalizedNavItem[] = []
  for (let item of items) {
    res = res.concat(flattenItems(item.items))
    res.push(item)
  }
  return res
}
