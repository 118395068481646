import classNames from 'classnames'
import * as React from 'react'
import useSiteMetadata from '../../hooks/use-site-metadata'
import './index.scss'

interface Props {
  centerAlign?: boolean
}

export default function Footer(props: Props) {
  const { centerAlign } = props
  const { title, organization, organizationSite } = useSiteMetadata()

  return (
    <div
      className={classNames('Footer', {
        'center-align': centerAlign,
      })}
      role="contentinfo"
    >
      <div className="Footer__flex">
        <div>{title}</div>
        <div>
          <span>&#169;&nbsp;</span>
          <span>{new Date().getFullYear()}</span>
          <span>,&nbsp;</span>
          <a href={organizationSite}>{organization}</a>
          <span>&nbsp;All Rights Reserved</span>
        </div>
      </div>
    </div>
  )
}
