import isBrowser from './is-browser'

/**
 * Determines whether the current href contains one of a set
 * of given help keys.
 */
const hrefHasHelpKey = (helpKeys: string[]) => {
  if (!isBrowser()) {
    return false
  }
  const params = new URL(location.href).searchParams
  const helpKey = params.get(`helpKey`)
  if (!helpKey) {
    return false
  }
  return helpKeys.includes(helpKey)
}

export default hrefHasHelpKey
