import { useStaticQuery, graphql } from 'gatsby'

type SiteMetadata = {
  title: string
  organization: string
  organizationSite: string
  contentModules: ContentModule[]
  contentPackages: ContentPackage[]
}

const useSiteMetadata = (): SiteMetadata => {
  const { site } = useStaticQuery(
    graphql`
      query ProductsQuery {
        site {
          siteMetadata {
            title
            organization
            organizationSite
            contentModules
            contentPackages
          }
        }
      }
    `
  )
  const {
    title,
    organization,
    organizationSite,
    contentModules,
    contentPackages,
  } = site.siteMetadata
  return {
    title,
    organization,
    organizationSite,
    contentModules: JSON.parse(contentModules),
    contentPackages: JSON.parse(contentPackages),
  }
}

export default useSiteMetadata

/**
 * Extracts a list of unique products from a collection of content modules.
 */
export const uniqueProducts = (contentModules: ContentModule[]): Product[] => {
  return contentModules.reduce(
    (acc: Product[], { id, name, description, url }) => {
      if (acc.find((item) => item.id === id) === undefined) {
        acc.push({ id, name, description, url })
      }
      return acc
    },
    []
  )
}
