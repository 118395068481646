import { Loader } from '@deque/cauldron-react'
import { navigate } from '@reach/router'
import React from 'react'
import {
  closeSearchModal,
  selectNextItem,
  selectPreviousItem,
  useSearch,
} from '../../../../context/Search'
import Group from './Group'
import './index.scss'

interface ResultsProps {
  groups: ResultGroup[]
  resultsRef: React.RefObject<HTMLDivElement>
  hideProductText?: boolean
}

export default function Results({
  groups,
  resultsRef,
  hideProductText = false,
}: ResultsProps) {
  const { state: searchState, dispatch: searchDispatch } = useSearch()
  const { fetching, query, resultGroups, selectedItem, selectedIndex } =
    searchState

  // Default state
  if (!fetching && query.length === 0) {
    return null
  }

  // Request in progress
  if (fetching) {
    return <Loader label="Fetching results" />
  }

  // Response received, but no results found
  if (!fetching && query.length > 0 && resultGroups.length === 0) {
    return (
      <div className="Search__NoResults" ref={resultsRef} tabIndex={0}>
        <span>No results found</span>
      </div>
    )
  }

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    switch (e.code) {
      case 'ArrowUp':
        e.preventDefault()
        selectPreviousItem(searchDispatch, searchState)
        break
      case 'ArrowDown':
        e.preventDefault()
        selectNextItem(searchDispatch, searchState)
        break
      case 'Enter':
        e.preventDefault()
        if (selectedItem) {
          closeSearchModal(searchDispatch)
          navigate(selectedItem.url)
        }
        break
    }
  }

  return (
    <div
      className="Search__Results"
      id="search-results-listbox"
      ref={resultsRef}
      role="listbox"
      aria-label="Search results"
      aria-activedescendant={selectedItem?.itemId}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      {groups.map((group, i) => {
        return <Group key={i} group={group} hideProductText={hideProductText} />
      })}
    </div>
  )
}
