import React, { useRef, useState, useEffect } from 'react'
import { SkipLink, Layout, Main } from '@deque/cauldron-react'
import PageNav from '../components/ContentPage/PageNav'
import { useGatsbyPageContext } from '../context/GatsbyPageContext'
import { VersionSelectModal } from '../components/ContentPage/VersionSelectModal'
import ArticleHeader from '../components/ContentPage/ArticleHeader'
import Breadcrumb from '../components/ContentPage/Breadcrumb'
import useSiteMetadata, { uniqueProducts } from '../hooks/use-site-metadata'
import './content-page.scss'
import ModuleSearch from '../components/Search/ModuleSearch'
import ArticleFooter from '../components/ContentPage/ArticleFooter'
import { Helmet } from 'react-helmet'
import createDocumentTitle from '../lib/document-title'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Logo from '../components/Header/Logo'
import { SendFeedbackModal } from '../components/ContentPage/SendFeedbackModal'
import ContactUs from '../components/Header/ContactUs'

interface ContentPageLayoutProps {
  children: React.ReactElement
}

export default function ContentPageLayout(props: ContentPageLayoutProps) {
  const { children } = props
  const page = useGatsbyPageContext()
  const { subtitle, productId, version, locale, latestVersion } = page
  const site = useSiteMetadata()
  const products = uniqueProducts(site.contentModules)
  const product = products.find(({ id }) => id === productId) as Product
  const [showNav, setShowNav] = useState(false)
  const [showVersionModal, setShowVersionModal] = React.useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = React.useState(false)
  const [collapseBreadcrumb, setCollapseBreadcrumb] = useState(false)
  const layoutRef: React.RefObject<HTMLDivElement> = useRef(null)
  const mainRef: React.RefObject<HTMLDivElement> = useRef(null)
  const showNavButtonRef: React.RefObject<HTMLButtonElement> = useRef(null)
  const hideNavButtonRef: React.RefObject<HTMLButtonElement> = useRef(null)

  const toggleNav = () => {
    const isShown = !showNav
    setShowNav(isShown)
    if (isShown) {
      hideNavButtonRef.current?.focus()
    } else {
      showNavButtonRef.current?.focus()
    }
  }

  const dismissNav = () => {
    setShowNav(false)
    const mainEl = mainRef.current
    if (!mainEl) {
      return
    }
    const onBlur = () => {
      mainEl.removeAttribute('tabindex')
      mainEl.removeEventListener('blur', onBlur)
    }
    mainEl.setAttribute('tabindex', '0')
    mainEl.addEventListener('blur', onBlur)
    mainEl.focus()
  }

  const pageNavSkiplinkLabel = `Skip to page navigation`

  // Collapse/expand breadcrumb
  useEffect(() => {
    const layoutEl = layoutRef.current as HTMLDivElement
    const update = () => {
      if (!collapseBreadcrumb && layoutEl.scrollTop > 0) {
        setCollapseBreadcrumb(true)
      } else if (collapseBreadcrumb && layoutEl.scrollTop === 0) {
        setCollapseBreadcrumb(false)
      }
    }
    update()
    layoutEl.addEventListener('scroll', update)
    return () => layoutEl.removeEventListener('scroll', update)
  }, [layoutRef, collapseBreadcrumb])

  // Setting id on the page-nav skiplink so it can be hidden
  // when the page nav is not permanent.
  // The Cauldron SkipLink component does not allow setting
  // classes or ids as props.
  useEffect(() => {
    const pageNavSkiplink = document.querySelector(
      `[aria-label="${pageNavSkiplinkLabel}"]`
    )
    if (pageNavSkiplink) {
      pageNavSkiplink.setAttribute('id', 'page-nav-skiplink')
    }
  }, [])

  // Scroll to the top of the main content area if the URL does not target a specific anchor.
  // Should run every time the page changes.
  useEffect(() => {
    if (location.hash.length <= 0) {
      mainRef.current?.scrollIntoView()
    }
  }, [page.title])

  // Scroll to the anchor specified by the URL hash.
  // Only needs to run on initial page load.
  useEffect(() => {
    const anchorId = location.hash.length && location.hash.replace(/^#/, '')
    if (!anchorId) {
      return
    }
    const anchorEl = document.getElementById(anchorId)
    if (!anchorEl) {
      return
    }
    anchorEl.scrollIntoView()
  }, [])

  return (
    <>
      <Helmet>
        <title>{createDocumentTitle(site.title, page.title)}</title>
        <meta name="docsearch:product" content={productId} />
        <meta name="docsearch:version" content={version} />
        <meta name="docsearch:language" content={locale} />
        <meta
          name="docsearch:isLatestVersion"
          content={JSON.stringify(version === latestVersion)}
        />
        {subtitle && <meta name="description" content={subtitle} />}
      </Helmet>

      <SkipLink
        target={'#page-navigation'}
        targetText="Page Navigation"
        aria-label={pageNavSkiplinkLabel}
      />
      <SkipLink
        target={'#main-content'}
        targetText="Main Content"
        aria-label="Skip to main content"
      />

      <VersionSelectModal
        show={showVersionModal}
        onClose={() => setShowVersionModal(false)}
      />

      <SendFeedbackModal
        show={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
      />

      <Header>
        <div className="cluster--left">
          <Logo />
          <span className="Header__product-name">{product.name}</span>
        </div>
        <div className="cluster--right">
          <ModuleSearch product={product} version={version} language={locale} />
          <ContactUs />
        </div>
      </Header>

      <PageNav
        show={showNav}
        dismissNav={dismissNav}
        hideNavButtonRef={hideNavButtonRef}
      />

      <Layout layoutRef={layoutRef}>
        <Main id="main-content" mainRef={mainRef}>
          <Breadcrumb
            collapsed={collapseBreadcrumb}
            toggleNav={toggleNav}
            showNavButtonRef={showNavButtonRef}
          />
          <article className="Article">
            <ArticleHeader
              mainRef={mainRef}
              title={page.title}
              subtitle={subtitle}
              productVersion={version}
              showVersionModal={() => setShowVersionModal(true)}
            />
            {children}
            <ArticleFooter
              showFeedbackModal={() => setShowFeedbackModal(true)}
            />
          </article>
          <Footer />
        </Main>
      </Layout>
    </>
  )
}
