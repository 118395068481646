import React from 'react'
import { Icon, Modal, ModalContent, ModalFooter } from '@deque/cauldron-react'
import Results from './Results'
import './index.scss'
import {
  useSearch,
  closeSearchModal,
  submitQuery,
  openSearchModal,
} from '../../../context/Search'
import { SearchOptions } from '@algolia/client-search'

interface SearchModalProps {
  modalTitle?: string
  hideProductText?: boolean
  inputLabel?: string
  searchOptions: SearchOptions
}

export default function SearchModal({
  modalTitle = 'Search',
  hideProductText = false,
  inputLabel = 'Search',
  searchOptions,
}: SearchModalProps) {
  const inputId = 'algolia-search-input'
  const formRef: React.RefObject<HTMLFormElement> = React.useRef(null)
  const inputRef: React.RefObject<HTMLInputElement> = React.useRef(null)
  const resultsRef: React.RefObject<HTMLDivElement> = React.useRef(null)
  const { state, dispatch } = useSearch()
  const { fetching, query, modalIsOpen, resultGroups } = state
  const footerId = 'search-modal-footer'

  const activateSearch = () => {
    if (typeof inputRef.current?.value !== 'undefined') {
      submitQuery(dispatch, inputRef.current.value, searchOptions)
    }
  }

  const resetSearchInput = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
    submitQuery(dispatch, '', searchOptions)
  }

  const focusSearchInput = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleResetClick = () => {
    resetSearchInput()
    focusSearchInput()
  }

  const handleInputKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter') {
      e.preventDefault()
      activateSearch()
    }
  }

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    activateSearch()
  }

  const focusResultsList = () => {
    if (resultsRef.current) {
      resultsRef.current.focus()
    }
  }

  // Set up keyboard shortcuts
  React.useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (
        modalIsOpen === false &&
        event.key === 'k' &&
        (event.metaKey || event.ctrlKey)
      ) {
        event.preventDefault()
        openSearchModal(dispatch)
      }
    }

    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  })

  // Focus the results list when results are updated
  React.useEffect(() => {
    if (!fetching && query.length > 0) {
      focusResultsList()
    }
  }, [resultGroups])

  return (
    <Modal
      className="Search"
      show={modalIsOpen}
      heading={<div>{modalTitle}</div>}
      onClose={() => {
        resetSearchInput()
        closeSearchModal(dispatch)
      }}
    >
      <ModalContent>
        <form
          className="Search__Form"
          ref={formRef}
          onSubmit={handleFormSubmit}
        >
          <span className="Search__GlassIcon">
            <Icon type="magnifying-glass" />
          </span>
          <input
            type="search"
            id={inputId}
            ref={inputRef}
            aria-label={inputLabel}
            placeholder={inputLabel}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            enterKeyHint="search"
            spellCheck="false"
            onKeyDown={handleInputKeyDown}
          />
          <div className="Search__Reset">
            <button type="reset" onClick={handleResetClick}>
              <Icon type="close" label="Reset search" />
            </button>
          </div>
        </form>
        <Results
          groups={resultGroups}
          resultsRef={resultsRef}
          hideProductText={hideProductText}
        />
      </ModalContent>
    </Modal>
  )
}
