import React from 'react'
import { SearchProvider } from '../../context/Search'
import SearchButton from './Button'
import SearchModal from './Modal'
import { SearchOptions } from '@algolia/client-search'

declare const DOCSEARCH_CONFIG: DocSearchConfig

interface SearchProps {
  buttonLabel?: string
  modalTitle?: string
  hideProductText?: boolean
  inputLabel?: string
  options?: SearchOptions
}

export default function Search({
  buttonLabel = 'Search...',
  modalTitle = 'Search',
  hideProductText = false,
  inputLabel = 'Search',
  options = {},
}: SearchProps) {
  const { appId, apiKey, indexName } = DOCSEARCH_CONFIG

  // Disable search if any config details are missing
  if (!appId || !apiKey || !indexName) {
    return null
  }

  options = {
    attributesToRetrieve: ['*'],
    highlightPreTag: '<span class="Item__Highlight">',
    highlightPostTag: '</span>',
    snippetEllipsisText: '...',
    ...options,
  }

  return (
    <SearchProvider>
      <SearchButton label={buttonLabel} />
      <SearchModal
        modalTitle={modalTitle}
        hideProductText={hideProductText}
        inputLabel={inputLabel}
        searchOptions={options}
      />
    </SearchProvider>
  )
}
