import { createContext, useContext } from 'react'

export const GatsbyPageContext = createContext<
  GatsbyPageContextObject | undefined
>(undefined)

export const useGatsbyPageContext = () => {
  const gatsbyPageContext = useContext(GatsbyPageContext)
  if (!gatsbyPageContext) {
    throw new Error(
      `useGatsbyPageContext must be used with a GatsbyPageContext.Provider`
    )
  }
  return gatsbyPageContext
}
