import algoliasearch from 'algoliasearch'
import { SearchOptions } from '@algolia/client-search'

declare const DOCSEARCH_CONFIG: DocSearchConfig

const { appId, apiKey, indexName } = DOCSEARCH_CONFIG
const client = algoliasearch(appId, apiKey)
const index = client.initIndex(indexName)

export default async function queryAlgolia(
  value: string,
  options: SearchOptions = {}
): Promise<Hit[] | undefined> {
  try {
    const response = await index.search(value, options)
    return response.hits as Hit[]
  } catch (err) {
    console.warn(err)
  }
}
