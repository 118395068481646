import React from 'react'
import { Icon, Sidebar, SideBarItem } from '@deque/cauldron-react'
import NavItem from './NavItem'
import { useGatsbyPageContext } from '../../context/GatsbyPageContext'
import flattenItems from '../../lib/flatten-nav-items'
import './PageNav.scss'
import { validate as isUuid } from 'uuid'

interface PageNavProps {
  show: boolean
  dismissNav: () => void
  hideNavButtonRef: React.RefObject<HTMLButtonElement>
}

export default function PageNav(props: PageNavProps) {
  const { show, dismissNav, hideNavButtonRef } = props
  const { pageId, navData, productId, version, locale } = useGatsbyPageContext()

  // Get ancestors of the active page, if the active page is included in the navigation hierarchy
  const activePage = flattenItems(navData.items).find(({ id }) => id === pageId)
  const activePageAncestors = activePage?.ancestors || []

  return (
    <Sidebar
      id="page-navigation"
      show={show}
      onDismiss={() => dismissNav()}
      onBlur={(e) => e.target.removeAttribute('tabindex')}
      navProps={{ 'aria-label': 'Page' }}
    >
      <SideBarItem
        className="SideBar__Header"
        autoClickLink={false}
        onClick={(e) => e.preventDefault()}
      >
        <button
          ref={hideNavButtonRef}
          onClick={(e) => {
            e.preventDefault()
            dismissNav()
          }}
        >
          <Icon type="close" label="Close navigation" />
        </button>
        <p className="Sidebar__Title">
          <span>Navigation</span>
        </p>
      </SideBarItem>
      {navData.items.map(({ id, title, items, ancestors }) => {
        const path =
          isUuid(id) === false
            ? `/${productId}/${version}/${locale}/${id}`
            : undefined
        return (
          <NavItem
            key={id}
            pageId={id}
            path={path}
            title={title}
            items={items}
            ancestors={ancestors}
            activePageAncestors={activePageAncestors}
            dismissNav={dismissNav}
          />
        )
      })}
    </Sidebar>
  )
}
