import React, { useEffect, useMemo } from 'react'
import './index.scss'
import { v4 as createUuid } from 'uuid'
import { useGatsbyPageContext } from '../../../context/GatsbyPageContext'
import createPagePath from '../../../lib/create-page-path'
import usePageIsHelpful from '../../../hooks/use-page-is-helpful'
import { Button, Link } from '@deque/cauldron-react'
import FreeTrialLink from '../FreeTrialLink'

interface ArticleFooterProps {
  showFeedbackModal(): void
}

export default function ArticleFooter(props: ArticleFooterProps) {
  const gatsbyPageContext = useGatsbyPageContext()
  const { contentPackage, freeTrialUrl } = gatsbyPageContext
  const pagePath = createPagePath(gatsbyPageContext)
  const [pageIsHelpful, setPageIsHelpful] = usePageIsHelpful(pagePath)

  // Generate unique id for label association
  const pageHelpfulHeadingId = useMemo(() => createUuid().substring(0, 8), [])

  const onPageIsHelpfulClick = (isHelpful: boolean) => {
    setPageIsHelpful(isHelpful)
  }

  const createRadioId = (value: boolean) => `page-helpful-radio-${value}`

  const onShowFeedbackModalClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    props.showFeedbackModal()
  }

  // Initialize checked state for "Is this page helpful?" radio buttons, only on mount.
  useEffect(() => {
    if (typeof pageIsHelpful !== 'boolean') {
      return
    }
    const radioEl = document.getElementById(createRadioId(pageIsHelpful))
    if (!radioEl) {
      return
    }
    radioEl.setAttribute('aria-checked', 'true')
  }, [])

  return (
    <div className="Article__Footer">
      <div role="radiogroup" aria-labelledby={pageHelpfulHeadingId}>
        <h2 id={pageHelpfulHeadingId}>Is this page helpful?</h2>
        <div className="Feedback__Thumbs">
          <button
            id={createRadioId(true)}
            role="radio"
            aria-checked={pageIsHelpful === true}
            onClick={() => onPageIsHelpfulClick(true)}
          >
            <img src="/images/light/icons/thumbs-up.svg" alt="Yes" />
          </button>
          <button
            id={createRadioId(false)}
            role="radio"
            aria-checked={pageIsHelpful === false}
            onClick={() => onPageIsHelpfulClick(false)}
          >
            <img src="/images/light/icons/thumbs-down.svg" alt="No" />
          </button>
        </div>
      </div>
      <div>
        <h2>Help us improve this page</h2>
        <Button variant="secondary" onClick={onShowFeedbackModalClick}>
          Send Feedback
        </Button>
      </div>
      {freeTrialUrl && (
        <div>
          <h2>Try {contentPackage.name}</h2>
          <FreeTrialLink url={freeTrialUrl} />
        </div>
      )}
      <div className="help-links">
        <h2>Still need help?</h2>
        <ul>
          <li>
            <Link href="https://www.deque.com/help-center/">
              Visit the Help Center
            </Link>
          </li>
          <li>
            <Link href="https://www.deque.com/get-accessibility-help">
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
