import React from 'react'
import { Loader, LoaderOverlay } from '@deque/cauldron-react'
import '@deque/cauldron-styles'
import './LoaderPage.scss'

export default function LoaderPage() {
  return (
    <div className="LoaderPage">
      <LoaderOverlay role="alert">
        <Loader label="Loading..." />
        <div>Loading...</div>
      </LoaderOverlay>
    </div>
  )
}
