import amplitudeJs, { AmplitudeClient, Config } from 'amplitude-js'
import TagManager from 'react-gtm-module'
import isBrowser from './is-browser'

declare const GOOGLE_TAG_MANAGER_ENABLED: boolean

/**
 * Default Ampltitude instance.
 */

let amplitude: AmplitudeClient

let didInitializeAmplitude = false

interface InitAmplitudeParams {
  amplitudeApiKey?: string
  distinctId?: string
}

/**
 * Initialize the Amplitude client.
 */

export const initAmplitude = ({
  amplitudeApiKey,
  distinctId,
}: InitAmplitudeParams = {}) => {
  // Only initialize Amplitude when provided an API key
  // and client has not already been initialized.
  if (!amplitudeApiKey || didInitializeAmplitude || !isBrowser()) {
    return
  }
  amplitude = amplitudeJs.getInstance()
  const config: Config = {
    includeUtm: true,
    saveParamsReferrerOncePerSession: false,
    includeGclid: true,
    includeReferrer: true,
    secureCookie: true,
    sameSiteCookie: 'Lax',
    // It's unnecessary to set cookies unless we need Amplitude to track anonymous users across subdomains.
    // This allows us to ignore the user's cookie preference vis-a-vis Amplitude config.
    // https://www.docs.developers.amplitude.com/data/sdks/javascript/#disable-cookies
    disableCookies: true,
  }
  amplitude.init(amplitudeApiKey, distinctId, config)
  didInitializeAmplitude = true
}

interface InitTagManagerParams {
  gtmId?: string
  allowCookies: boolean
}

/**
 * Initialize Google Tag Manager.
 *
 * Will noop unless all of the following are true:
 *
 * 1. GTM is enabled via the environment flag
 * 2. A `gtmId` is provided
 * 3. The user has allowed non-essential cookies
 */

export const initTagManager = ({
  gtmId,
  allowCookies,
}: InitTagManagerParams) => {
  if (!GOOGLE_TAG_MANAGER_ENABLED || !gtmId || !allowCookies) {
    return
  }
  TagManager.initialize({ gtmId })
}

/**
 * Set the Amplitude user id.
 *
 * Useful for changing the user id after client initialization, for instance when a new user logs in.
 *
 * Will noop if Amplitude has not been initialized.
 */

export const setAmplitudeUserId = (userId: string) => {
  if (!didInitializeAmplitude) {
    return
  }
  amplitudeJs.getInstance().setUserId(userId)
}

enum AmplitudeEvents {
  PAGE_FEEDBACK = 'page:feedback',
  PAGE_FREE_TRIAL = 'page:free_trial',
  PAGE_HELPFUL = 'page:helpful',
  PAGE_VIEW = 'page:view',
  EXAMPLE_CLICKTHROUGH = 'example:clickthrough',
}

type AmplitudeEventData = Record<string, any>

/**
 * Send the given `event` with optional `data`.
 *
 * If the Amplitude client has not been initialized, calling this will simply noop.
 */

const sendAmplitudeEvent = async (
  event: AmplitudeEvents,
  data?: AmplitudeEventData
): Promise<void> => {
  if (!didInitializeAmplitude) {
    return
  }

  amplitude.logEvent(event, data, (responseCode, responseBody, details) => {
    if (responseCode === 200) {
      return
    }
    console.warn(
      [
        `An problem occurred while logging the ${event} event`,
        `Response: ${responseBody}`,
        `Reason: ${details?.reason}`,
      ].join('\n')
    )
  })
}

/**
 * User viewed a content page.
 */

export const pageView = (data: {
  productId: string
  version: string
  locale: string
  pageId: string
}): Promise<void> => {
  return sendAmplitudeEvent(AmplitudeEvents.PAGE_VIEW, data)
}

/**
 * User provided a response to "Is this page helpful?"
 */

export const pageIsHelpful = (data: {
  path: string
  value: boolean
}): Promise<void> => {
  return sendAmplitudeEvent(AmplitudeEvents.PAGE_HELPFUL, data)
}

/**
 * User submitted feedback form.
 */

export const pageFeedback = (data: {
  productId: string
  version: string
  locale: string
  pageId: string
  feedback: string
}): Promise<void> => {
  return sendAmplitudeEvent(AmplitudeEvents.PAGE_FEEDBACK, data)
}

/**
 * User clicked through from the example catalog.
 */

export const exampleClick = (data: {
  language: string
  name: string
  destination: 'github' | 'docs'
}): Promise<void> => {
  return sendAmplitudeEvent(AmplitudeEvents.EXAMPLE_CLICKTHROUGH, data)
}

/**
 * User clicked a "Free Trial" link.
 */

export const freeTrialClick = (data: {
  productId: string
  version: string
  locale: string
  pageId: string
}): Promise<void> => {
  return sendAmplitudeEvent(AmplitudeEvents.PAGE_FREE_TRIAL, data)
}
