declare const SITE_ORIGIN: string

/**
 * Create a URL for an item, replacing the default origin of the item's URL with the one supplied by environment configuration.
 */
export default function replaceUrlOrigin(url: string): string {
  if (!SITE_ORIGIN) {
    return url
  }
  const { origin } = new URL(url)
  return url.replace(origin, SITE_ORIGIN)
}
