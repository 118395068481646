import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Select,
  SelectOption,
} from '@deque/cauldron-react'
import { navigate } from 'gatsby'
import React from 'react'
import { useGatsbyPageContext } from '../../context/GatsbyPageContext'
import './VersionSelectModal.scss'
import semverSort from '../../lib/semver-sort'

type VersionSelectModalProps = {
  show: boolean
  onClose(): void
}

export function VersionSelectModal(props: VersionSelectModalProps) {
  const { version, productVersions, productId, locale } = useGatsbyPageContext()
  const [selectedVersion, setSelectedVersion] = React.useState(version)

  const versionOptions: SelectOption[] = semverSort(productVersions)
    .reverse()
    .map((version: string) => ({ key: version, value: version }))

  return (
    <Modal
      show={props.show}
      heading={<div>Select Product Version</div>}
      onClose={() => props.onClose()}
    >
      <ModalContent>
        <Select
          label="Product version"
          value={selectedVersion}
          onChange={(e) => setSelectedVersion(e.target.value)}
          options={versionOptions}
        />
      </ModalContent>
      <ModalFooter>
        <Button
          onClick={() => {
            props.onClose()
            navigate(`/${productId}/${selectedVersion}/${locale}`)
          }}
        >
          Apply
        </Button>
        <Button variant="secondary" onClick={() => props.onClose()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
