import React from 'react'
import isBrowser from '../lib/is-browser'

declare const DISABLE_AUTH: boolean

type Context = Auth.Context
type State = Auth.State
type Dispatch = (action: Action) => void
type ProviderProps = Auth.ProviderProps
type Profile = Auth.Profile

type Action =
  | {
      type: ActionType.UPDATE_AUTHENTICATED
      authenticated: boolean
    }
  | {
      type: ActionType.UPDATE_USER
      profile: Profile
      token: string
    }

export enum ActionType {
  UPDATE_AUTHENTICATED = 'update-authenticated',
  UPDATE_USER = 'update-user',
}

const AuthContext = React.createContext<Context | undefined>(undefined)

const defaultState: State = {
  keycloak: null,
  authenticated: DISABLE_AUTH,
  profile: {},
  token: '',
}

/**
 * Create a function that initializes the default state,
 * given a Keycloak instance.
 */

function createInitState(keycloak: any): (defaultState: State) => State {
  return (defaultState: State) => ({
    ...defaultState,
    keycloak,
  })
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.UPDATE_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.authenticated,
      }
    case ActionType.UPDATE_USER:
      return {
        ...state,
        profile: action.profile,
        token: action.token,
      }
    default:
      // @ts-ignore: handle default case in the event that an unhandled action type is hard-coded
      throw new Error(`unhandled action type: ${action.type}`)
  }
}

function AuthProvider(props: ProviderProps) {
  const { keycloak } = props
  const [state, dispatch] = React.useReducer(
    reducer,
    defaultState,
    createInitState(keycloak)
  )
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AuthContext.Provider>
  )
}

function useAuth(): Context {
  // Mock the context during SSR.
  // The provider is only available in the browser.
  // See /gatsby-browser.js
  if (!isBrowser()) {
    return { state: defaultState, dispatch: () => {} }
  }
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

/**
 * Authenticate a user (via the Keycloak authentication flow, if necessary) and update the context state accordingly.
 *
 * Will noop if any of the following are true:
 *
 * - Authentication is disabled
 * - The user is already authenticated
 */

async function authenticate(keycloak: any, dispatch: Dispatch): Promise<void> {
  if (DISABLE_AUTH || (keycloak.authenticated && keycloak.token)) {
    return
  }
  /**
   * `login-required` will authenticate the client if the user is logged-in to Keycloak or display the login page if not.
   *
   * `check-sso` will only authenticate the client if the user is already logged-in, if the user is not logged-in the browser will be redirected back to the application and remain unauthenticated.
   *
   * https://www.keycloak.org/docs/latest/securing_apps/#javascript-adapter-reference
   */
  const onLoad = 'login-required'
  try {
    const authenticated: boolean = await keycloak.init({
      onLoad,
      checkLoginIframe: false,
    })
    dispatch({
      type: ActionType.UPDATE_AUTHENTICATED,
      authenticated,
    })
    if (!authenticated) {
      return
    }
    const profile: Profile = await keycloak.loadUserProfile()
    dispatch({
      type: ActionType.UPDATE_USER,
      profile,
      token: keycloak.token,
    })
  } catch (error) {
    console.error(error)
  }
}

export { AuthProvider, useAuth, authenticate }
