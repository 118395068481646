import React from 'react'
import Search from '.'

interface Props {
  product: Product
  version: string
  language: string
}

export default function ModuleSearch(props: Props) {
  const { product, version, language } = props
  const options = {
    filters: `product:${product.id} AND version:${version} AND language:${language}`,
  }

  return (
    <Search
      modalTitle={`Search ${product.name} v${version}`}
      options={options}
      hideProductText
    />
  )
}
