import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { pageIsHelpful as analyticsEvent } from '../lib/analytics'
import isBrowser from '../lib/is-browser'

type ParsedData = {
  [key: string]: string
}

const STORAGE_KEY = 'page-is-helpful'

export default function usePageIsHelpful(
  path: string
): [boolean | undefined, Dispatch<SetStateAction<boolean | undefined>>] {
  const getInitialValue = () => getPageValue(path)
  const [value, setValue] = useState(getInitialValue)

  // Get value from localStorage when user visits a new page
  useEffect(() => {
    setValue(getPageValue(path))
  }, [path])

  // Update localStorage when value is changed
  useEffect(() => {
    if (typeof value !== 'boolean') {
      return
    }
    analyticsEvent({ path, value })
    try {
      localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({
          ...getData(),
          [path]: value,
        })
      )
    } catch (error) {
      console.log(error)
    }
  }, [value])

  return [value, setValue]
}

/**
 * Get data for all pages from localStorage.
 */
function getData(): ParsedData {
  if (!isBrowser()) {
    return {}
  }
  const data = localStorage.getItem(STORAGE_KEY)
  if (data === null) {
    return {}
  }
  try {
    const parsedData: ParsedData = JSON.parse(data)
    return parsedData
  } catch (error) {
    console.log(error)
    return {}
  }
}

/**
 * Get a value for a specific page from localStorage.
 */
function getPageValue(path: string): boolean | undefined {
  const data = getData()
  if (!data.hasOwnProperty(path)) {
    return
  }
  return Boolean(data[path])
}
