exports.components = {
  "component---src-components-content-page-index-tsx": () => import("./../../../src/components/ContentPage/index.tsx" /* webpackChunkName: "component---src-components-content-page-index-tsx" */),
  "component---src-components-cors-resource-tsx": () => import("./../../../src/components/CorsResource.tsx" /* webpackChunkName: "component---src-components-cors-resource-tsx" */),
  "component---src-components-page-list-tsx": () => import("./../../../src/components/PageList.tsx" /* webpackChunkName: "component---src-components-page-list-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agora-access-tsx": () => import("./../../../src/pages/agora-access.tsx" /* webpackChunkName: "component---src-pages-agora-access-tsx" */),
  "component---src-pages-devtools-for-web-examples-tsx": () => import("./../../../src/pages/devtools-for-web-examples.tsx" /* webpackChunkName: "component---src-pages-devtools-for-web-examples-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

