import React from 'react'

const PAGE_RESULT_LABEL = 'Result type: Page'
const HEADING_RESULT_LABEL = 'Result type: Heading'
const CONTENT_RESULT_LABEL = 'Result type: Content'

type TypeIconData = {
  className: string
  label: string
}

const iconMap = new Map<HitType, TypeIconData>()
iconMap.set('lvl0', { className: 'far fa-file', label: PAGE_RESULT_LABEL })
iconMap.set('lvl1', {
  className: 'fas fa-hashtag',
  label: HEADING_RESULT_LABEL,
})
iconMap.set('lvl2', {
  className: 'fas fa-hashtag',
  label: HEADING_RESULT_LABEL,
})
iconMap.set('lvl3', {
  className: 'fas fa-hashtag',
  label: HEADING_RESULT_LABEL,
})
iconMap.set('lvl4', {
  className: 'fas fa-hashtag',
  label: HEADING_RESULT_LABEL,
})
iconMap.set('lvl5', {
  className: 'fas fa-hashtag',
  label: HEADING_RESULT_LABEL,
})
iconMap.set('lvl6', {
  className: 'fas fa-hashtag',
  label: HEADING_RESULT_LABEL,
})
iconMap.set('content', {
  className: 'fas fa-bars',
  label: CONTENT_RESULT_LABEL,
})

interface IconProps {
  type: HitType
}

export default function Icon({ type }: IconProps) {
  const iconData = iconMap.get(type)

  if (!iconData) {
    return null
  }

  return (
    <span>
      <i className={iconData.className} aria-hidden={true}></i>
      <span className="Offscreen">{`${iconData.label}`}</span>
    </span>
  )
}
