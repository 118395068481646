import React, { useState } from 'react'
import { SideBarItem } from '@deque/cauldron-react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import '@deque/cauldron-styles'
import './NavItem.scss'
import { useGatsbyPageContext } from '../../context/GatsbyPageContext'
import { validate as isUuid } from 'uuid'

interface NavItemProps {
  pageId: string
  title: string
  items: NavTypes.NormalizedNavItem[]
  ancestors: string[]
  activePageAncestors: string[]
  dismissNav: () => void
  path?: string
}

export default function NavItem(props: NavItemProps) {
  const {
    pageId,
    title,
    items,
    ancestors,
    activePageAncestors,
    dismissNav,
    path,
  } = props
  const {
    productId,
    version,
    locale,
    pageId: activePageId,
  } = useGatsbyPageContext()
  const isActive = activePageId === pageId
  const isAncestorOfActivePage = activePageAncestors.includes(pageId)
  const [expanded, setExpanded] = useState(isActive || isAncestorOfActivePage)

  // Expand item if it is an ancestor of the active page
  React.useEffect(() => {
    if (isAncestorOfActivePage) {
      setExpanded(true)
    }
  }, [isAncestorOfActivePage])

  return (
    <SideBarItem
      className={classNames(
        'NavItem',
        `NavItem--depth${ancestors.length.toString()}`
      )}
    >
      <div className="NavItem__Title">
        {items.length > 0 && (
          <button
            className={classNames('NavItem__ToggleButton', {
              'NavItem__ToggleButton--expanded': expanded,
            })}
            onClick={() => setExpanded(!expanded)}
            disabled={isAncestorOfActivePage}
          >
            <span className="Offscreen">
              {`${expanded ? 'Hide' : 'Show'} children of ${title}`}
            </span>
          </button>
        )}
        {typeof path === 'undefined' ? (
          <div className="NavItem__TitleText">
            <span>{title}</span>
          </div>
        ) : (
          <Link
            className="NavItem__TitleText"
            to={path}
            aria-current={isActive ? 'page' : undefined}
            onClick={() => {
              if (items.length > 0) {
                setExpanded(true)
              }
              dismissNav()
            }}
          >
            <span>{title}</span>
          </Link>
        )}
      </div>
      {items.length > 0 && (
        <ul
          className={classNames('NavItem__List', {
            'NavItem__List--expanded': expanded,
          })}
        >
          {items.map(({ id, title, items, ancestors }) => {
            const path =
              isUuid(id) === false
                ? `/${productId}/${version}/${locale}/${id}`
                : undefined
            return (
              <NavItem
                key={id}
                pageId={id}
                path={path}
                title={title}
                items={items}
                ancestors={ancestors}
                activePageAncestors={activePageAncestors}
                dismissNav={dismissNav}
              />
            )
          })}
        </ul>
      )}
    </SideBarItem>
  )
}
