import coerce from 'semver/functions/coerce'
import sort from 'semver/functions/sort'

/**
 * Sorts a list of semver versions. Handles "abbreviated" versions (e.g., "2.12").
 */

export default function semverSort(list: string[]): string[] {
  // Create mapping of raw values to coerced semver values
  const map = list.map((raw) => {
    const coerced = coerce(raw)
    if (!coerced) {
      throw new Error(`Invalid semver version: ${raw}`)
    }
    return {
      raw,
      coerced,
    }
  })

  // Sort the coerced values
  const sorted = sort(map.map((item) => item.coerced))

  // Return list of raw values in sorted order
  return sorted.map((coerced) => {
    const mapItem = map.find((item) => item.coerced === coerced)
    if (!mapItem) {
      throw new Error('Unexpected error')
    }
    return mapItem.raw
  })
}
