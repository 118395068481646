import { SearchOptions } from '@algolia/client-search'
import React from 'react'
import queryAlgolia from '../components/Search/utils/query'
import replaceUrlOrigin from '../components/Search/utils/replace-url-origin'
import decodeHtml from '../lib/decode-html'

type Context = { state: State; dispatch: Dispatch }

type State = {
  fetching: boolean
  query: string
  modalIsOpen: boolean
  resultCount: number
  resultGroups: ResultGroup[]
  selectedIndex?: number
  selectedItem?: ResultItem
}

type Dispatch = (action: Action) => void

enum ActionType {
  UPDATE_FETCHING = 'update-fetching',
  UPDATE_QUERY = 'update-query',
  UPDATE_MODAL_IS_OPEN = 'update-modal-is-open',
  UPDATE_RESULT_COUNT = 'update-result-count',
  UPDATE_RESULT_GROUPS = 'update-result-groups',
  UPDATE_SELECTED_INDEX = 'update-selected-index',
  UPDATE_SELECTED_ITEM = 'update-selected-item',
}

type Action =
  | {
      type: ActionType.UPDATE_FETCHING
      value: boolean
    }
  | {
      type: ActionType.UPDATE_QUERY
      value: string
    }
  | {
      type: ActionType.UPDATE_MODAL_IS_OPEN
      value: boolean
    }
  | {
      type: ActionType.UPDATE_RESULT_COUNT
      value: number
    }
  | {
      type: ActionType.UPDATE_RESULT_GROUPS
      value: ResultGroup[]
    }
  | {
      type: ActionType.UPDATE_SELECTED_INDEX
      value: number | undefined
    }
  | {
      type: ActionType.UPDATE_SELECTED_ITEM
      value: ResultItem | undefined
    }

type ProviderProps = { children: React.ReactNode }

const initialState: State = {
  fetching: false,
  query: '',
  modalIsOpen: false,
  resultCount: 0,
  resultGroups: [],
  selectedIndex: undefined,
  selectedItem: undefined,
}

const SearchContext = React.createContext<Context>({
  state: initialState,
  dispatch: () => {},
})

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.UPDATE_FETCHING:
      return {
        ...state,
        fetching: action.value,
      }
    case ActionType.UPDATE_QUERY:
      return {
        ...state,
        query: action.value,
      }
    case ActionType.UPDATE_MODAL_IS_OPEN:
      return {
        ...state,
        modalIsOpen: action.value,
      }
    case ActionType.UPDATE_RESULT_COUNT:
      return {
        ...state,
        resultCount: action.value,
      }
    case ActionType.UPDATE_RESULT_GROUPS:
      return {
        ...state,
        resultGroups: action.value,
      }
    case ActionType.UPDATE_SELECTED_INDEX:
      return {
        ...state,
        selectedIndex: action.value,
      }
    case ActionType.UPDATE_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: action.value,
      }
    default:
      // @ts-ignore: handle default case in the event that an unhandled action type is hard-coded
      throw new Error(`unhandled action type: ${action.type}`)
  }
}

function SearchProvider({ children }: ProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return (
    <SearchContext.Provider value={{ state, dispatch }}>
      {children}
    </SearchContext.Provider>
  )
}

function useSearch(): Context {
  const context = React.useContext(SearchContext)
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider')
  }
  return context
}

/**
 * Submit query to Algolia API and update context state with result groups.
 */

function submitQuery(
  dispatch: Dispatch,
  value: string,
  options: SearchOptions
): void {
  dispatch({
    type: ActionType.UPDATE_QUERY,
    value,
  })

  if (value.length <= 0) {
    updateResultGroups(dispatch, [])
  } else {
    dispatch({
      type: ActionType.UPDATE_FETCHING,
      value: true,
    })
    asyncQuery()
  }

  async function asyncQuery() {
    const hits = await queryAlgolia(value, options || {})
    if (typeof hits === 'undefined') {
      return
    }
    dispatch({
      type: ActionType.UPDATE_FETCHING,
      value: false,
    })
    const groups = createResultGroups(hits)
    updateResultGroups(dispatch, groups)
  }
}

function openSearchModal(dispatch: Dispatch): void {
  dispatch({
    type: ActionType.UPDATE_MODAL_IS_OPEN,
    value: true,
  })
}

function closeSearchModal(dispatch: Dispatch): void {
  dispatch({
    type: ActionType.UPDATE_MODAL_IS_OPEN,
    value: false,
  })
}

function updateResultGroups(dispatch: Dispatch, groups: ResultGroup[]): void {
  const resultCount = groups.reduce((acc, group) => {
    return (acc += group.items.length)
  }, 0)

  dispatch({
    type: ActionType.UPDATE_RESULT_COUNT,
    value: resultCount,
  })
  dispatch({
    type: ActionType.UPDATE_RESULT_GROUPS,
    value: groups,
  })
  dispatch({
    type: ActionType.UPDATE_SELECTED_INDEX,
    value: resultCount > 0 ? 0 : undefined,
  })
  dispatch({
    type: ActionType.UPDATE_SELECTED_ITEM,
    value: resultCount > 0 ? getResultItemByIndex(groups, 0) : undefined,
  })
}

function getResultItemByIndex(
  groups: ResultGroup[],
  index: number
): ResultItem {
  const items = groups.reduce((acc: ResultItem[], group) => {
    return acc.concat(group.items)
  }, [])

  const res = items.find((item) => item.itemIndex === index)

  if (!res) {
    throw new Error(`Failed to find result item with index: ${index}`)
  }

  return res
}

function selectItem(dispatch: Dispatch, item: ResultItem): void {
  dispatch({
    type: ActionType.UPDATE_SELECTED_INDEX,
    value: item.itemIndex,
  })
  dispatch({
    type: ActionType.UPDATE_SELECTED_ITEM,
    value: item,
  })
}

function selectPreviousItem(dispatch: Dispatch, state: State): void {
  const { resultCount, resultGroups, selectedItem } = state

  let targetIndex: number

  if (selectedItem) {
    targetIndex =
      selectedItem.itemIndex > 0 ? selectedItem.itemIndex - 1 : resultCount - 1
  } else {
    targetIndex = resultCount - 1
  }

  const item = getResultItemByIndex(resultGroups, targetIndex)
  selectItem(dispatch, item)
}

function selectNextItem(dispatch: Dispatch, state: State): void {
  const { resultCount, resultGroups, selectedItem } = state

  let targetIndex: number

  if (selectedItem) {
    targetIndex =
      selectedItem.itemIndex < resultCount - 1 ? selectedItem.itemIndex + 1 : 0
  } else {
    targetIndex = 0
  }

  const item = getResultItemByIndex(resultGroups, targetIndex)
  selectItem(dispatch, item)
}

function createResultGroups(hits: Hit[]): ResultGroup[] {
  interface HitGroup {
    title: string
    product: string
    path: string
    items: Hit[]
  }

  let hitGroups: HitGroup[] = []

  for (const hit of hits) {
    const title = decodeHtml(hit.hierarchy.lvl0)
    const { product } = hit
    const path = new URL(hit.url).pathname
    const group = hitGroups.find((group) => {
      return title === group.title && path === group.path
    })

    if (group) {
      // Add hit to existing group
      group.items.push(hit)
      continue
    }

    // Create a new group
    hitGroups.push({
      title,
      product,
      path,
      items: [hit],
    })
  }

  // Assign item ids and indices, and replace URL origin if needed
  let itemIndex = 0
  const resultGroups: ResultGroup[] = hitGroups.map(
    ({ title, product, path, items }) => {
      return {
        title,
        product,
        path,
        items: items.map((item) => {
          return {
            ...item,
            itemId: `search-result-item-${itemIndex}`,
            itemIndex: itemIndex++,
            url: replaceUrlOrigin(item.url),
          }
        }),
      }
    }
  )

  return resultGroups
}

export {
  SearchProvider,
  useSearch,
  submitQuery,
  openSearchModal,
  closeSearchModal,
  updateResultGroups,
  selectItem,
  selectPreviousItem,
  selectNextItem,
}
