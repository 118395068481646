import React from 'react'
import { useAuth } from '../context/Auth'
import { useCookiePermission } from '../context/CookiePermission'
import { useDistinctId, setDistinctId } from '../context/DistinctId'
import { initAmplitude, initTagManager } from '../lib/analytics'

declare const AMPLITUDE_API_KEY: string
declare const GOOGLE_TAG_MANAGER_ID: string

interface AnalyticsProps {
  children: React.ReactNode
}

export default function Analytics(props: AnalyticsProps) {
  const { state: distinctId, dispatch: distinctIdDispatch } = useDistinctId()
  const { state: cookiePermissionState } = useCookiePermission()
  const { allowCookies } = cookiePermissionState
  const { state: authState } = useAuth()
  const { token } = authState

  initAmplitude({
    amplitudeApiKey: AMPLITUDE_API_KEY,
    distinctId,
  })

  // Attempt to initialize GTM when the user selects a cookie preference.
  React.useEffect(() => {
    initTagManager({
      gtmId: GOOGLE_TAG_MANAGER_ID,
      allowCookies,
    })
  }, [allowCookies])

  // Update distinct id when the user's token changes
  React.useEffect(() => {
    setDistinctId(distinctIdDispatch, token)
  }, [token])

  return <>{props.children}</>
}
