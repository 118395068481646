import React from 'react'
import { Link } from 'gatsby'
import './index.scss'

export default function Logo() {
  return (
    <Link className="Logo" to="/">
      <img src={`/images/light/deque.png`} alt="Deque" />
      <span className="Logo__subtitle">Docs</span>
    </Link>
  )
}
