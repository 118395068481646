import { Button } from '@deque/cauldron-react'
import React from 'react'
import { useAsync } from 'react-async-hook'
import {
  useCookiePermission,
  setAllowCookies,
  setUserHasResponded,
  readFromStorage,
} from '../../context/CookiePermission'
import './index.scss'

export default function CookieNotice() {
  const { state, dispatch } = useCookiePermission()
  const asyncRead = useAsync(readFromStorage, [dispatch])

  const onAcceptEssential = () => {
    setUserHasResponded(dispatch, true)
    setAllowCookies(dispatch, false)
  }

  const onAcceptAll = () => {
    setUserHasResponded(dispatch, true)
    setAllowCookies(dispatch, true)
  }

  const content = (
    <div className="CookieNotice" role="complementary" data-nosnippet>
      <h1>
        <span>This Site uses Cookies</span>
      </h1>
      <div className="CookieNotice__Content">
        <p>
          Some cookies are essential to the proper functioning of this site. We
          use essential cookies to keep you signed in to your account. We use
          other cookies to enhance your browsing experience and analyze our
          traffic. For more information, see our{' '}
          <a href="https://deque.com/privacy-policy/">Privacy Policy</a>.
        </p>
        <p>
          By clicking <strong>Accept All</strong>, you consent to our use of
          non-essential cookies.
        </p>
      </div>
      <div className="CookieNotice__Buttons">
        <Button variant="secondary" onClick={onAcceptEssential}>
          Accept Essential
        </Button>
        <Button onClick={onAcceptAll}>Accept All</Button>
      </div>
    </div>
  )

  return (
    <>
      {asyncRead.loading && null}
      {asyncRead.status === 'success' && !state.userHasResponded && content}
    </>
  )
}
