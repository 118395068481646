import { Icon } from '@deque/cauldron-react'
import React from 'react'
import { openSearchModal, useSearch } from '../../../context/Search'
import './index.scss'

interface SearchButtonProps {
  label?: string
}

export default function SearchButton({ label = 'Search' }: SearchButtonProps) {
  const { dispatch } = useSearch()

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    openSearchModal(dispatch)
  }

  return (
    <div className="Search__Button">
      <button aria-haspopup="dialog" onClick={handleClick}>
        <Icon type="magnifying-glass" />
        <span className="text-label">{label}</span>
      </button>
    </div>
  )
}
