import React from 'react'
import isBrowser from '../lib/is-browser'

type Context = { state: State; dispatch: Dispatch }
type State = { allowCookies: boolean; userHasResponded: boolean }
type Dispatch = (action: Action) => void
type Action =
  | {
      type: ActionType.SET_ALLOWED
      value: boolean
    }
  | {
      type: ActionType.SET_RESPONDED
      value: boolean
    }
enum ActionType {
  SET_ALLOWED = 'set-allowed',
  SET_RESPONDED = 'set-responded',
}
type ProviderProps = { children: React.ReactNode }

const ALLOWED_KEY = 'deque_cookies_allowed'
const RESPONDED_KEY = 'deque_cookies_has_responded'

const defaultState: State = {
  allowCookies: false,
  userHasResponded: false,
}

const CookiePermissionContext = React.createContext<Context>({
  state: defaultState,
  dispatch: () => {},
})

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.SET_ALLOWED:
      return {
        ...state,
        allowCookies: action.value,
      }
    case ActionType.SET_RESPONDED:
      return {
        ...state,
        userHasResponded: action.value,
      }
    default:
      // @ts-ignore: handle default case in the event that an unhandled action type is hard-coded
      throw new Error(`unhandled action type: ${action.type}`)
  }
}

function CookiePermissionProvider(props: ProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, defaultState)
  return (
    <CookiePermissionContext.Provider value={{ state, dispatch }}>
      {props.children}
    </CookiePermissionContext.Provider>
  )
}

function useCookiePermission(): Context {
  const context = React.useContext(CookiePermissionContext)
  if (context === undefined) {
    throw new Error(
      'useCookiePermission must be used within a CookiePermissionProvider'
    )
  }
  return context
}

async function readFromStorage(dispatch: Dispatch): Promise<void> {
  if (!isBrowser()) {
    return
  }
  const allowCookiesValue = localStorage.getItem(ALLOWED_KEY)
  const userHasRespondedValue = localStorage.getItem(RESPONDED_KEY)
  const allowCookies =
    allowCookiesValue !== null
      ? allowCookiesValue === 'true'
      : defaultState.allowCookies
  const userHasResponded =
    userHasRespondedValue !== null
      ? userHasRespondedValue === 'true'
      : defaultState.userHasResponded
  dispatch({ type: ActionType.SET_ALLOWED, value: allowCookies })
  dispatch({ type: ActionType.SET_RESPONDED, value: userHasResponded })
}

function setAllowCookies(dispatch: Dispatch, value: boolean): void {
  if (typeof value !== 'boolean') {
    return
  }
  try {
    localStorage.setItem(ALLOWED_KEY, String(value))
    dispatch({ type: ActionType.SET_ALLOWED, value })
  } catch (error) {
    console.error(error)
  }
}

function setUserHasResponded(dispatch: Dispatch, value: boolean): void {
  if (typeof value !== 'boolean') {
    return
  }
  try {
    localStorage.setItem(RESPONDED_KEY, String(value))
    dispatch({ type: ActionType.SET_RESPONDED, value })
  } catch (error) {
    console.error(error)
  }
}

export {
  CookiePermissionProvider,
  useCookiePermission,
  setAllowCookies,
  setUserHasResponded,
  readFromStorage,
}
