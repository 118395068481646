interface PageProps {
  productId: string
  version: string
  locale: string
  pageId: string
}

export default function createPagePath({
  productId,
  version,
  locale,
  pageId,
}: PageProps) {
  if (
    typeof productId !== 'string' ||
    productId.length <= 0 ||
    typeof version !== 'string' ||
    version.length <= 0 ||
    typeof locale !== 'string' ||
    locale.length <= 0 ||
    typeof pageId !== 'string' ||
    pageId.length <= 0
  ) {
    throw new Error('One or more invalid parameters provided')
  }
  return `/${productId}/${version}/${locale}/${pageId}`
}
