import {
  Button,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
  TextField,
} from '@deque/cauldron-react'
import React from 'react'
import { useGatsbyPageContext } from '../../../context/GatsbyPageContext'
import './index.scss'
import { pageFeedback as sendPageFeedbackEvent } from '../../../lib/analytics'

interface SendFeedbackModalProps {
  show: boolean
  onClose(): void
}

type FormStatus = 'idle' | 'invalid' | 'sending' | 'sent' | 'error'

export function SendFeedbackModal(props: SendFeedbackModalProps) {
  const { productId, version, locale, pageId } = useGatsbyPageContext()
  const [status, setStatus] = React.useState<FormStatus>('idle')
  const successMessageRef = React.useRef<HTMLParagraphElement>(null)
  const errorMessageRef = React.useRef<HTMLParagraphElement>(null)
  const textareaRef = React.useRef<HTMLTextAreaElement>(null)

  const isValidLength = (value: string) =>
    value.length >= 15 && value.length <= 1000

  const handleSend = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    const textField = document.getElementById(
      'feedback-field'
    ) as HTMLInputElement

    if (!textField) {
      console.error('Could not find feedback field')
    }

    const trimmedValue = textField.value.trim()

    if (!isValidLength(trimmedValue)) {
      setStatus('invalid')
      textField.focus()
      return
    }

    try {
      setStatus('sending')
      await sendPageFeedbackEvent({
        productId,
        version,
        locale,
        pageId,
        feedback: textField.value,
      })
      setStatus('sent')
      successMessageRef?.current?.focus()
    } catch (err) {
      console.error(err)
      setStatus('error')
      errorMessageRef?.current?.focus()
    }
  }

  const handleClose = () => {
    props.onClose()
    setStatus('idle')
  }

  const handleChange = () => {
    const currentValue = textareaRef?.current?.value
    if (typeof currentValue !== 'string') {
      return
    }
    const currentValueIsValid = isValidLength(currentValue.trim())
    if (status === 'invalid' && currentValueIsValid) {
      setStatus('idle')
    }
    if (!currentValueIsValid) {
      setStatus('invalid')
    }
  }

  return (
    <Modal
      className="SendFeedbackModal"
      show={props.show}
      heading={<div>Send Feedback</div>}
      onClose={handleClose}
    >
      <ModalContent>
        {(status === 'idle' || status === 'invalid') && (
          <form id="feedback-form">
            <p>Tell us how we can improve this page.</p>
            <TextField
              id="feedback-field"
              className="FeedbackField"
              label="Your Feedback"
              multiline
              required
              error={
                status === 'invalid'
                  ? 'Feedback must be between 15 and 1000 characters'
                  : undefined
              }
              fieldRef={textareaRef}
              onChange={handleChange}
            />
          </form>
        )}
        {status === 'sending' && <Loader label="Sending feedback" />}
        {status === 'sent' && (
          <p tabIndex={-1} ref={successMessageRef}>
            Thanks for your feedback!
          </p>
        )}
        {status === 'error' && (
          <p tabIndex={-1} ref={errorMessageRef}>
            There was an error sending your feedback. Please try again later.
          </p>
        )}
      </ModalContent>
      <ModalFooter>
        {status === 'idle' || status === 'invalid' ? (
          <>
            <Button type="submit" form="feedback-form" onClick={handleSend}>
              Send
            </Button>
            <Button variant="secondary" onClick={() => handleClose()}>
              Cancel
            </Button>
          </>
        ) : (
          <Button onClick={() => handleClose()}>Close</Button>
        )}
      </ModalFooter>
    </Modal>
  )
}
