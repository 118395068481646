import './src/styles/print.scss'
import './src/styles/prism.scss'
import './src/styles/reset.scss'
import './src/styles/theme.scss'

import React from 'react'
import { AuthProvider } from './src/context/Auth'
import Keycloak from 'keycloak-js'

export const wrapRootElement = ({ element }) => {
  const keycloak = new Keycloak('/keycloak.json')
  return <AuthProvider keycloak={keycloak}>{element}</AuthProvider>
}
