import React from 'react'
import Item from '../Item'
import './index.scss'
import contentPackages from '../../../../../config/content-packages'

interface Props {
  group: ResultGroup
  hideProductText?: boolean
}

export default function Group({ group, hideProductText = false }: Props) {
  const { title, product, items } = group
  const groupRef = React.useRef<HTMLDivElement>(null)
  const productName = contentPackages.find(({ id }) => id === product)?.name
  const label = hideProductText ? title : `${title} - ${productName}`

  return (
    <>
      <div className="Group" ref={groupRef} role="group" aria-label={label}>
        <div className="Group__title" aria-hidden="true">
          <div>{title}</div>
          {hideProductText ? null : (
            <div className="Group__product-name">{productName}</div>
          )}
        </div>
        <div className="Group__hits">
          {items.map((item, i) => {
            return <Item key={i} item={item} groupRef={groupRef} />
          })}
        </div>
      </div>
    </>
  )
}
