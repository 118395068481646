import { Icon } from '@deque/cauldron-react'
import React from 'react'
import './index.scss'

export default function ContactUs() {
  const url = 'https://www.deque.com/get-accessibility-help'

  return (
    <div className="contact-us">
      <a className="link__wide" href={url}>
        <Icon type="question-circle-alt" />
        <span className="text">Contact Us</span>
      </a>
      <a className="link__narrow" href={url}>
        <Icon type="question-circle-alt" label="Contact Us" />
      </a>
    </div>
  )
}
