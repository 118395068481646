import React from 'react'
import getDistinctId from '@deque/distinct-id-client'
import { setAmplitudeUserId } from '../lib/analytics'

declare const DEV: boolean

type Context = { state: State; dispatch: Dispatch }
type State = DistinctId
export type Dispatch = (action: Action) => void
type Action = { type: 'update'; id: string }
type DistinctId = string | undefined
type ProviderProps = { children: React.ReactNode }

const DistinctIdContext = React.createContext<Context | undefined>(undefined)

function reducer(_state: State, action: Action): State {
  switch (action.type) {
    case 'update':
      return action.id
    default:
      throw new Error(`unhandled action type: ${action.type}`)
  }
}

function DistinctIdProvider(props: ProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, undefined)
  return (
    <DistinctIdContext.Provider value={{ state, dispatch }}>
      {props.children}
    </DistinctIdContext.Provider>
  )
}

function useDistinctId(): Context {
  const context = React.useContext(DistinctIdContext)
  if (context === undefined) {
    throw new Error('useDistinctId must be used within a DistinctIdProvider')
  }
  return context
}

/**
 * Update the current user's distinct id.
 *
 * @param dispatch DistinctIdProvider's action dispatcher
 * @param token Token used to generate the distinct id
 */

async function setDistinctId(
  dispatch: Dispatch,
  token?: string
): Promise<void> {
  try {
    const id = await getDistinctId({
      host: DEV ? 'https://axe.dequelabs.com' : undefined,
      token,
    })
    dispatch({ type: 'update', id })
    // Send new value to Amplitude
    setAmplitudeUserId(id)
  } catch (error) {
    console.error(error)
  }
}

export { DistinctIdProvider, useDistinctId, setDistinctId }
